import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getAuth, GoogleAuthProvider} from "firebase/auth"
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCblwRWxZH2nRLZivP63G4xvAf6r3luSPc",
  authDomain: "ratemyuni-f3bcf.firebaseapp.com",
  projectId: "ratemyuni-f3bcf",
  storageBucket: "ratemyuni-f3bcf.appspot.com",
  messagingSenderId: "878063317452",
  appId: "1:878063317452:web:87c639eff57652d5caed69",
  measurementId: "G-ZLH2N01PP9"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);


export { db, provider, auth };